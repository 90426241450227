import { graphql } from "gatsby"
import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const principles = [
  {
    img: require("../svgs/curious.svg"),
    name: "Be passionately curious",
  },
  {
    img: require("../svgs/ideas.svg"),
    name: "Think perpendicular!",
  },
  {
    img: require("../svgs/change.svg"),
    name: "Embrace change",
  },
]

const events = ["wb-4PlQApCQ", "MtftNowN-wk"]

const contents = ["MvVkFiXn8Yg", "Rfcq3b-Cek8"]

const YouTubeLink = ({ id }) => (
  <Link to={`https://www.youtube.com/watch?v=${id}`}>
    <img src={`https://img.youtube.com/vi/${id}/hqdefault.jpg`} />
  </Link>
)

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Farmaceutica Younger" />

    <section className="p-10 bg-primary-300">
      <div className="max-w-xl mx-auto">
        <h1 className="text-2xl font-light text-center"> CHI SIAMO </h1>
        <p className="text-center mt-4">
          <strong>Farmaceutica Younger</strong> nasce come blog di divulgazione
          su temi inerenti al mondo del farmaceutico da una idea di Silvia
          Vernotico nel 2017. Sempre più spinta dalla necessità di condividere
          questi temi con persone appassionate come lei del settore, il{" "}
          <strong>18 settembre 2020</strong> prende vita l'Associazione nata con
          l'obiettivo di tendere una mano ai giovani che hanno voglia di
          conoscere sempre di più sul mondo del farmaceutico.
        </p>
        <a
          href="https://forms.gle/r77YhnUeSakHNPKH7"
          target="_blank"
          className="text-center bg-primary-800 hover:bg-primary-900 py-2 mt-10 w-full inline-block rounded"
        >
          DIVENTA SOCIO
        </a>
        <p className="text-center mt-10">
          Entra anche tu a far parte della Famiglia di Farmaceutica Younger.
        </p>
      </div>
    </section>
    <section className="p-6 bg-primary-300">
      <div>
        <h2 className="text-2xl font-light text-center">
          I PRINCIPI CHE CI GUIDANO
        </h2>

        <div className="flex flex-col md:flex-row max-w-2xl m-auto mt-4">
          {principles.map((p, idx) => {
            console.log(p.img)
            return (
              <div
                key={idx}
                className="m-auto p-4 max-w-xs flex text-sm flex-col justify-items-end"
              >
                <img src={p.img} className="w-auto" />
                <p className="text-center text-lg py-3"> {p.name}</p>
              </div>
            )
          })}
        </div>
      </div>
    </section>

    <section className="bg-primary-300">
      <h2 className="inline-block w-full mt-10 text-2xl font-light text-center">
        EVENTI 2020
      </h2>

      <div className="flex flex-col md:flex-row justify-center mt-4">
        {events.map(id => (
          <div className="max-w-md mx-auto my-1 md:mx-4">
            <YouTubeLink key={id} id={id} />
          </div>
        ))}
      </div>
    </section>

    <section className="bg-primary-300 ">
      <h2 className="inline-block w-full mt-10 text-2xl font-light text-center">
        ALCUNI MOMENTI INSIEME
      </h2>

      <div className="flex flex-row overflow-x-scroll mt-4">
        {data.allFile.edges.map(({ node }) => (
          <img className="max-w-sm h-64" src={node.childImageSharp.fixed.src} />
        ))}
      </div>
    </section>

    <section className="bg-primary-300 pb-10">
      <h2 className="inline-block w-full mt-10 text-2xl font-light text-center">
        CONTENUTI DIGITALI
      </h2>

      <div className="flex flex-col md:flex-row justify-center mt-4">
        {contents.map(id => (
          <div className="max-w-md mx-auto my-1 md:mx-4">
            <YouTubeLink key={id} id={id} />
          </div>
        ))}
      </div>
    </section>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query IndexPate {
    allFile(filter: { relativePath: { regex: "/showcase/" } }) {
      edges {
        node {
          absolutePath
          childImageSharp {
            fixed(width: 800, jpegQuality: 90) {
              src
            }
          }
        }
      }
    }
  }
`
